import React from "react";

const PrintIconSVG = () => {
	return (
		<div>
			<svg
				height='50px'
				width='50px'
				version='1.1'
				id='Layer_1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 512 512'
				fill='#000000'>
				<g id='SVGRepo_bgCarrier' stroke-width='0'></g>
				<g
					id='SVGRepo_tracerCarrier'
					stroke-linecap='round'
					stroke-linejoin='round'></g>
				<g id='SVGRepo_iconCarrier'>
					{" "}
					<path
						style={{ fill: "#424242" }}
						d='M342.302,488.41H169.697c-8.174,0-14.803-6.629-14.803-14.803s6.629-14.803,14.803-14.803h172.605 c8.174,0,14.803,6.629,14.803,14.803S350.478,488.41,342.302,488.41z'></path>{" "}
					<path
						style={{ fill: "#ababab" }}
						d='M473.513,149.416h-66.619h-2.219v94.741c0,13.08-10.604,23.685-23.685,23.685H131.01 c-13.082,0-23.685-10.604-23.685-23.685v-94.741h-3.699H38.488c-13.082,0-23.685,10.604-23.685,23.685v276.82 c0,13.082,10.604,23.685,23.685,23.685H93.51c7.63,0,13.816-6.185,13.816-13.816v-20.831c0-13.082,10.604-23.685,23.685-23.685 H380.99c13.082,0,23.685,10.604,23.685,23.685v20.833c0,7.63,6.185,13.816,13.816,13.816h55.022 c13.082,0,23.685-10.604,23.685-23.685v-276.82C497.198,160.021,486.593,149.416,473.513,149.416z'></path>{" "}
					<g>
						{" "}
						<path
							style={{ fill: "#424242" }}
							d='M473.512,488.41H418.49c-15.78,0-28.619-12.839-28.619-28.619v-20.831 c0-4.898-3.984-8.882-8.882-8.882H131.011c-4.898,0-8.882,3.984-8.882,8.882v20.831c0,15.78-12.839,28.619-28.619,28.619H38.488 C17.265,488.41,0,471.145,0,449.921v-276.82c0-21.223,17.265-38.488,38.488-38.488h68.838c8.174,0,14.803,6.629,14.803,14.803 v94.741c0,4.898,3.984,8.882,8.882,8.882H380.99c4.897,0,8.88-3.984,8.88-8.882v-94.741c0-8.174,6.629-14.803,14.803-14.803h68.838 c21.223,0,38.488,17.265,38.488,38.488v276.82C512,471.145,494.735,488.41,473.512,488.41z M419.477,458.803h54.035 c4.898,0,8.882-3.984,8.882-8.882v-276.82c0-4.898-3.984-8.882-8.882-8.882h-54.035v79.937c0,21.223-17.265,38.488-38.487,38.488 H131.011c-21.223,0-38.488-17.265-38.488-38.488v-79.937H38.488c-4.898,0-8.882,3.984-8.882,8.882v276.82 c0,4.898,3.984,8.882,8.882,8.882h54.035v-19.844c0-21.223,17.265-38.488,38.488-38.488h249.977 c21.223,0,38.488,17.265,38.488,38.488L419.477,458.803L419.477,458.803z'></path>{" "}
						<path
							style={{ fill: "#424242" }}
							d='M380.99,282.645H131.01c-21.223,0-38.488-17.265-38.488-38.488V117.73 c0-10.262,3.989-19.913,11.237-27.177l55.494-55.651c7.273-7.294,16.951-11.311,27.253-11.311H380.99 c21.223,0,38.488,17.265,38.488,38.488v182.078C419.478,265.38,402.214,282.645,380.99,282.645z M186.506,53.197 c-2.376,0-4.61,0.927-6.288,2.61l-55.496,55.651c-1.673,1.677-2.594,3.904-2.594,6.271v126.427c0,4.898,3.984,8.882,8.882,8.882 h249.98c4.898,0,8.882-3.984,8.882-8.882V62.079c0-4.898-3.984-8.882-8.882-8.882H186.506z'></path>{" "}
						<path
							style={{ fill: "#424242" }}
							d='M317.467,376.918c-8.174,0-14.803-6.629-14.803-14.803v-33.103c0-11.541,9.388-20.929,20.929-20.929 h27.985c8.174,0,14.803,6.629,14.803,14.803c0,8.174-6.629,14.803-14.803,14.803H332.27v24.425 C332.27,370.289,325.641,376.918,317.467,376.918z'></path>{" "}
						<path
							style={{ fill: "#424242" }}
							d='M396.682,376.918c-8.174,0-14.803-6.629-14.803-14.803v-33.103c0-11.541,9.388-20.929,20.929-20.929 h27.985c8.174,0,14.803,6.629,14.803,14.803c0,8.174-6.629,14.803-14.803,14.803h-19.308v24.425 C411.485,370.289,404.856,376.918,396.682,376.918z'></path>{" "}
					</g>{" "}
				</g>
			</svg>
		</div>
	);
};

export default PrintIconSVG;
