import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import usTranslation from '../locale/us/us.json';
import ptTranslation from '../locale/pt/pt.json';

const language = localStorage.getItem('language');
i18n.use(initReactI18next).init({
  resources: {
    us: {
      translation: usTranslation,
    },
    pt: {
      translation: ptTranslation,
    },
  },
  fallbackLng: language ? language : 'us', // Set 'ar' as the default language
  interpolation: {
    escapeValue: false,
  },
  lng: language ? language : 'us', // Set 'ar' as the initial language
});

export default i18n;
