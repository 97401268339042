import {
  ClockCircleOutlined,
  CheckOutlined,
  UsergroupDeleteOutlined,
  RocketOutlined,
  NotificationFilled,
  TrophyFilled,
  SubnodeOutlined,
  CalendarOutlined,
  FileDoneOutlined,
  PieChartFilled,
  FileOutlined,
  FlagFilled,
  HomeOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
  WalletOutlined,
  FileSyncOutlined,
  FlagOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { disable } from 'workbox-navigation-preload';
import getPermissions from '../../utils/getPermissions';
import getUserFromToken from '../../utils/getUserFromToken';
import { useTranslation } from 'react-i18next';

const Sidenav = ({ color, sideNavOpenKeys }) => {
  const { t } = useTranslation();

  const user = getUserFromToken();
  const permissions = getPermissions();
  const hasPermission = (item) => {
    return permissions?.includes(item ? item : '');
  };

  const menu = [
    {
      label: (
        <NavLink to='/admin/dashboard'>
          <span>{t('sidebar.dashboard')}</span>
        </NavLink>
      ),
      key: 'dashboard',
      icon: <HomeOutlined />,
    },

    (hasPermission('create-user') ||
      hasPermission('readAll-user') ||
      hasPermission('readAll-role') ||
      hasPermission('readAll-designation') ||
      hasPermission('readAll-department')) && {
      label: t('sidebar.hr'),
      key: 'hr',
      icon: <UserOutlined />,
      children: [
        hasPermission('create-user') && {
          label: (
            <NavLink to='/admin/hr/staffs/new'>
              <span>{t('sidebar.new.employee')}</span>
            </NavLink>
          ),

          key: 'staffs',
          icon: <UsergroupAddOutlined />,
        },
        hasPermission('readAll-user') && {
          label: (
            <NavLink to='/admin/hr/staffs'>
              <span>{t('sidebar.employee.list')}</span>
            </NavLink>
          ),
          key: 'users',
          icon: <UsergroupAddOutlined />,
        },
        hasPermission('readAll-role') && {
          label: (
            <NavLink to='/admin/role'>
              <span>{t('sidebar.role.permission')}</span>
            </NavLink>
          ),
          key: 'roleAndPermissions',
          icon: <UserSwitchOutlined />,
        },
        hasPermission('readAll-designation') && {
          label: (
            <NavLink to='/admin/designation/'>
              <span>{t('sidebar.designation')}</span>
            </NavLink>
          ),
          key: 'designation',
          icon: <UserSwitchOutlined />,
        },
        hasPermission('readAll-department') && {
          label: (
            <NavLink to='/admin/department'>
              <span>{t('sidebar.department')}</span>
            </NavLink>
          ),
          key: 'department',
          icon: <UserSwitchOutlined />,
        },
      ],
    },

    (hasPermission('create-attendance') ||
      hasPermission('readAll-attendance')) && {
      label: t('sidebar.attendance'),
      key: 'attendance',
      icon: <ClockCircleOutlined />,
      children: [
        hasPermission('create-attendance') && {
          label: (
            <NavLink to='/admin/attendance'>
              <span>{t('sidebar.attendance')}</span>
            </NavLink>
          ),
          key: 'attendance',
          icon: <FileDoneOutlined />,
        },
        hasPermission('readSingle-attendance') && {
          label: (
            <NavLink to={`/admin/attendance/user/${user}`}>
              <span>{t('sidebar.myattendance')}</span>
            </NavLink>
          ),
          key: 'myAttendance',
          icon: <FileDoneOutlined />,
        },
      ],
    },

    (hasPermission('create-payroll') || hasPermission('readAll-payroll')) && {
      label: t('sidebar.payroll'),
      key: 'payroll',
      icon: <WalletOutlined />,
      children: [
        hasPermission('create-payroll') && {
          label: (
            <NavLink to='/admin/payroll/new'>
              <span>{t('sidebar.calculate.payroll')}</span>
            </NavLink>
          ),
          key: 'calculatePayroll',
          icon: <FileDoneOutlined />,
        },
        hasPermission('readAll-payroll') && {
          label: (
            <NavLink to='/admin/payroll/list'>
              <span>{t('sidebar.payslip.list')}</span>
            </NavLink>
          ),
          key: 'payslipList',
          icon: <FileOutlined />,
        },
      ],
    },

    hasPermission('readAll-shift') && {
      label: t('sidebar.shift'),
      key: 'shift',
      icon: <ClockCircleOutlined />,
      children: [
        hasPermission('readAll-shift') && {
          label: (
            <NavLink to='/admin/shift'>
              <span>{t('sidebar.shift')}</span>
            </NavLink>
          ),
          key: 'newShift',
          icon: <FileDoneOutlined />,
        },
      ],
    },

    hasPermission('readAll-employmentStatus') && {
      label: t('sidebar.employment'),
      key: 'employementStatus',
      icon: <RocketOutlined />,
      children: [
        hasPermission('readAll-employmentStatus') && {
          label: (
            <NavLink to='/admin/employment-status'>
              <span>{t('sidebar.status')}</span>
            </NavLink>
          ),
          key: 'employementStatus',
          icon: <FileDoneOutlined />,
        },
      ],
    },

    (hasPermission('create-leaveApplication') ||
      hasPermission('readAll-leaveApplication') ||
      hasPermission('readSingle-leaveApplication')) && {
      label: t('sidebar.leave'),
      key: 'leave',
      icon: <UsergroupDeleteOutlined />,
      children: [
        hasPermission('create-leaveApplication') && {
          label: (
            <NavLink to='/admin/leave/new'>
              <span>{t('sidebar.new.leave')}</span>
            </NavLink>
          ),
          key: 'newLeave',
          icon: <SubnodeOutlined />,
        },
        hasPermission('readAll-leaveApplication') && {
          label: (
            <NavLink to='/admin/leave'>
              <span>{t('sidebar.leave.status')}</span>
            </NavLink>
          ),
          key: 'leaveStatus',
          icon: <FileDoneOutlined />,
        },
        hasPermission('readSingle-leaveApplication') && {
          label: (
            <NavLink to={`/admin/leave/user/${user}`}>
              <span>{t('sidebar.myleaves')}</span>
            </NavLink>
          ),
          key: 'myLeaves',
          icon: <FileDoneOutlined />,
        },
      ],
    },

    (hasPermission('readAll-weeklyHoliday') ||
      hasPermission('readAll-publicHoliday')) && {
      label: t('sidebar.holiday'),
      key: 'holiday',
      icon: <CalendarOutlined />,
      children: [
        hasPermission('readAll-weeklyHoliday') && {
          label: (
            <NavLink to='/admin/holiday/week'>
              <span>{t('sidebar.weeklyholiday')}</span>
            </NavLink>
          ),
          key: 'weeklyHoliday',
          icon: <PieChartFilled />,
        },
        hasPermission('readAll-publicHoliday') && {
          label: (
            <NavLink to='/admin/holiday/public'>
              <span>{t('sidebar.publicholiday')}</span>
            </NavLink>
          ),
          key: 'publicHoliday',
          icon: <PieChartFilled />,
        },
      ],
    },

    hasPermission('readAll-leavePolicy') && {
      label: t('sidebar.leavepolicy'),
      key: 'leavePolicy',
      icon: <CalendarOutlined />,
      children: [
        hasPermission('readAll-leavePolicy') && {
          label: (
            <NavLink to='/admin/leave-policy'>
              <span>{t('sidebar.leavepolicy')}</span>
            </NavLink>
          ),
          key: 'leavePolicy',
          icon: <PieChartFilled />,
        },
      ],
    },

    hasPermission('readAll-announcement') && {
      label: t('sidebar.announcement'),
      key: 'announcement',
      icon: <NotificationFilled />,
      children: [
        hasPermission('readAll-announcement') && {
          label: (
            <NavLink to='/admin/announcement'>
              <span>{t('sidebar.announcement')}</span>
            </NavLink>
          ),
          key: 'newLeave',
          icon: <FlagFilled />,
        },
      ],
    },

    (hasPermission('readAll-account') ||
      hasPermission('readAll-transaction') ||
      hasPermission('create-transaction')) && {
      label: t('sidebar.accounts'),
      key: 'accounts',
      icon: <WalletOutlined />,
      children: [
        hasPermission('readAll-account') && {
          label: (
            <NavLink to='/admin/account/'>
              <span>{t('sidebar.account')}</span>
            </NavLink>
          ),
          key: 'accountList',
          icon: <UnorderedListOutlined />,
        },
        hasPermission('create-transaction') && {
          label: (
            <NavLink to='/admin/transaction/create'>
              <span>{t('sidebar.new.transaction')}</span>
            </NavLink>
          ),
          key: 'newTransaction',
          icon: <CheckOutlined />,
        },
        hasPermission('readAll-transaction') && {
          label: (
            <NavLink to='/admin/transaction/'>
              <span>{t('sidebar.transaction.list')}</span>
            </NavLink>
          ),
          key: 'transactionList',
          icon: <UnorderedListOutlined />,
        },
      ],
    },

    hasPermission('readAll-account') && {
      label: t('sidebar.financereport'),
      key: 'report',
      icon: <FlagOutlined />,
      children: [
        hasPermission('readAll-account') && {
          label: (
            <NavLink to='/admin/account/trial-balance'>
              <span>{t('sidebar.trialbalance')}</span>
            </NavLink>
          ),
          key: 'trialBalance',
          icon: <FileDoneOutlined />,
        },
        hasPermission('readAll-account') && {
          label: (
            <NavLink to='/admin/account/balance-sheet'>
              <span>{t('sidebar.balancesheet')}</span>
            </NavLink>
          ),
          key: 'balanceSheet',
          icon: <FileOutlined />,
        },
        hasPermission('readAll-account') && {
          label: (
            <NavLink to='/admin/account/income'>
              <span>{t('sidebar.incomestatement')}</span>
            </NavLink>
          ),
          key: 'incomeStatement',
          icon: <FileSyncOutlined />,
        },
      ],
    },

    (hasPermission('crate-award') || hasPermission('readAll-award')) && {
      label: t('sidebar.awards'),
      key: 'award',
      icon: <TrophyFilled />,
      children: [
        hasPermission('create-award') && {
          label: (
            <NavLink to='/admin/award/new'>
              <span>{t('sidebar.new.award')}</span>
            </NavLink>
          ),
          key: 'newAward',
          icon: <TrophyFilled />,
        },

        hasPermission('readAll-award') && {
          label: (
            <NavLink to='/admin/award'>
              <span>{t('sidebar.award')}</span>
            </NavLink>
          ),
          key: 'award',
          icon: <TrophyFilled />,
        },
      ],
    },

    (hasPermission('create-project') ||
      hasPermission('readAll-project') ||
      hasPermission('create-projectTeam') ||
      hasPermission('create-milestone') ||
      hasPermission('readAll-priority') ||
      hasPermission('create-task-Status')) && {
      label: t('sidebar.project'),
      key: 'project',
      icon: <SettingOutlined />,
      children: [
        hasPermission('create-project') && {
          label: (
            <NavLink to='/admin/project/new'>
              <span>{t('sidebar.add.project')}</span>
            </NavLink>
          ),
          key: 'project',
          icon: <SettingOutlined />,
        },
        hasPermission('readAll-project') && {
          label: (
            <NavLink to='/admin/project'>
              <span>{t('sidebar.all.project')}</span>
            </NavLink>
          ),
          key: 'allProject',
          icon: <SettingOutlined />,
        },
        hasPermission('create-projectTeam') && {
          label: (
            <NavLink to='/admin/team'>
              <span>{t('sidebar.team')}</span>
            </NavLink>
          ),
          key: 'team',
          icon: <SettingOutlined />,
        },
        (hasPermission('create-priority') ||
          hasPermission('readAll-priority')) && {
          label: (
            <NavLink to='/admin/task-priority'>
              <span>{t('sidebar.task.priority')}</span>
            </NavLink>
          ),
          key: 'taskPriority',
          icon: <SettingOutlined />,
        },
        hasPermission('create-milestone') && {
          label: (
            <NavLink to='/admin/milestone'>
              <span>{t('sidebar.add.milestone')}</span>
            </NavLink>
          ),
          key: 'milestone',
          icon: <SettingOutlined />,
        },

        hasPermission('create-taskStatus') && {
          label: (
            <NavLink to='/admin/task-status'>
              <span>{t('sidebar.add.task.status')}</span>
            </NavLink>
          ),
          key: 'taskStatus',
          icon: <SettingOutlined />,
        },
      ],
    },

    hasPermission('readAll-setting') && {
      label: t('sidebar.settings'),
      key: 'settings',
      icon: <SettingOutlined />,
      children: [
        hasPermission('readAll-setting') && {
          label: (
            <NavLink to='/admin/company-setting'>
              <span>{t('sidebar.company.setting')}</span>
            </NavLink>
          ),
          key: 'invoiceSetting',
          icon: <SettingOutlined />,
        },
      ],
    },
  ];

  return (
    <div>
      <Menu theme='dark' mode='inline' items={menu} className='sidenav-menu ' />
    </div>
  );
};

export default Sidenav;
