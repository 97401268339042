import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

import { Button, Col, Dropdown, Menu, Row, Space } from 'antd';

import { LogoutOutlined, UserOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import ReactCountryFlag from 'react-country-flag';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const toggler = [
  <svg
    width='20'
    height='20'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 448 512'
    key={0}
  >
    <path d='M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z'></path>
  </svg>,
];

function Header({ onPress, collapsed, handleCollapsed }) {
  const { t, i18n } = useTranslation();
  useEffect(() => window.scrollTo(0, 0));

  const [selectedOption, setSelectedOption] = useState({
    value: 'us',
    label: `${t('language.english')}`,
    flag: 'us',
  });

  const isLogged = localStorage.getItem('isLogged');
  const user = localStorage.getItem('user');
  const [language, setLanguage] = useState('');
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    // console.log('lng ', lng, selectedOption);
    localStorage.setItem('language', lng);
    // setSelectedOption(selectedOption);
  };
  const languageDropdownList = [
    {
      key: 'EN',
      label: (
        <button onClick={() => changeLanguage('us')}>
          <Space>
            <img src='/US.svg' alt='us.svg' width={30} />
            English
          </Space>
        </button>
      ),
    },
    {
      key: 'PT',
      label: (
        <button onClick={() => changeLanguage('pt')}>
          <Space>
            <img src='/PT.svg' alt='pt.svg' width={30} />
            Portuguese{' '}
          </Space>
        </button>
      ),
    },
  ];

  useEffect(() => {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', 'us');
      setLanguage('us');
      return;
    }
    setLanguage(localStorage.getItem('language'));
  }, [i18n.language]);

  const items = [
    {
      key: '1',
      label: (
        <p
          style={{ margin: 0, padding: '0.2rem 0.5rem' }}
          className='flex align-items-center p-0.5'
        >
          <UserOutlined style={{ fontSize: '16px' }} />{' '}
          <span className='logout-text font-weight-bold me-2 ms-1'>{user}</span>
        </p>
      ),
    },
    {
      key: '2',
      label: (
        <p
          style={{ margin: 0, padding: '0.2rem 0.5rem' }}
          className='flex align-items-center'
        >
          <Link to='/admin/auth/logout' className={styles.logoutLink}>
            <LogoutOutlined className='text-danger' />
            <span className='logout-text font-weight-bold'>Log Out</span>
          </Link>
        </p>
      ),
    },
  ];

  const [isDarkMode, setDarkMode] = useState(false);

  const toggleDarkMode = (checked) => {
    setDarkMode(checked);
  };

  useEffect(() => {
    if (isDarkMode) document.body.className = 'dark-theme';
    if (!isDarkMode) document.body.className = 'light-theme';
  }, [isDarkMode]);

  const customOptionRenderer = (option) => (
    <div>
      <ReactCountryFlag countryCode={option.flag} svg />
      <span style={{ marginLeft: '8px' }}>{option.label}</span>
    </div>
  );

  const languageOptions = [
    { value: 'us', label: `${t('language.english')}`, flag: 'us' },
    { value: 'pt', label: `${t('language.portuguese')}`, flag: 'pt' },
  ];

  useEffect(() => {
    setSelectedOption(
      languageOptions.find((option) => option.value === i18n.language)
    );
  }, [i18n.language]);

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={4}>
          <div className={styles.sidebarTogglerPC}>
            {isLogged &&
              React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: `${styles.trigger}`,
                  onClick: () => handleCollapsed(!collapsed),
                }
              )}
          </div>
        </Col>
        <Col span={24} md={20} className={styles.headerControl}>
          <Dropdown
            overlay={
              <Menu items={languageDropdownList} placement='bottomLeft' />
            }
            trigger={'click'}
          >
            <button>
              <img
                src={`/${language.toUpperCase()}.svg`}
                alt='lang'
                width={30}
              />
            </button>
          </Dropdown>

          {/* <Select
            options={languageOptions}
            formatOptionLabel={customOptionRenderer}
            value={selectedOption}
            onChange={(selectedOption) => changeLanguage(selectedOption.value)}
            className=''
          /> */}

          <DarkModeSwitch
            style={{ margin: '1rem' }}
            checked={isDarkMode}
            onChange={toggleDarkMode}
            size={20}
          />
          {/* {isLogged && (
            <Typography.Title
              level={5}
              style={{ margin: 0 }}
              className="me-3 flex align-items-center"
            >
              <UserOutlined style={{ fontSize: "16px" }} />{" "}
              <span className="logout-text font-weight-bold me-2 ms-1">
                {user}
              </span>
            </Typography.Title>
          )} */}

          {!isLogged && (
            <Link to='/admin/auth/login' className='btn-sign-in text-dark'>
              <span></span>
            </Link>
          )}

          {isLogged && (
            <Button
              type='link'
              className={styles.sidebarTogglerMobile}
              onClick={() => onPress()}
              style={{ boxShadow: 'none' }}
            >
              {toggler}
            </Button>
          )}
          {isLogged && (
            <div>
              <Dropdown
                overlay={<Menu items={items} />}
                placement='bottomLeft'
                className='user-dropdown'
              >
                <Button className='user-btn'>
                  <UserOutlined style={{ fontSize: '16px' }} />
                </Button>
              </Dropdown>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}

export default Header;
